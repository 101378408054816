



































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsFormSection from '@/components/VsFormSection/Index.vue'
import {
    getImportById,
    downloadReport,
} from '@/api/consoleApi/imports'
import axios from 'axios'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'

@Component({
    name: 'ImportDetailsDrawer',
    components: {
        VsFormSection,
        VsSectionHeader,
    },
})
export default class extends Vue {
    @Prop({ default: '', required: false }) importId!: string
    private showImportNotFound = false
    private open = false
    public loading = false
    private importData: any = null
    $refs: any

    get downloadParam () {
        return !!this.$route?.query?.download
    }

    get listId () {
        return this.$route?.params?.listId
    }

    @Watch('importId', { immediate: true, deep: true })
    private toggleDrawer (importId: string) {
        if (importId) {
            this.openDrawer(importId)
        } else {
            this.closeDrawer()
        }
    }

    public async openDrawer (importId: string) {
        this.loading = true
        this.open = true
        this.importData = null
        await this.getImportById(importId)
        this.loading = false
    }

    public closeDrawer () {
        this.open = false
    }

    private async getImportById (importId: string) {
        this.showImportNotFound = false
        try {
            const resp = await getImportById(importId)
            this.importData = resp.data.data
            if (this.downloadParam) {
                this.$router.replace({ query: {} })
                this.downloadReport()
            }
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    this.showImportNotFound = true
                }
            }
            console.log(e)
        }
    }

    private async downloadReport () {
        try {
            const response = await downloadReport(this.importId)
            const filename = this.importData.report_file_name
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    this.$root.$vsToast({
                        heading: 'Impossibile scaricare il file, report non disponibile',
                        timeout: 3000,
                        aspect: VsToastAspectEnum.alert,
                    })
                }
            }
            console.log(e)
        }
    }

    private getStatusAspect (status: string) {
        switch (status) {
            case 'processing': return 'info'
            case 'failed': return 'alert'
            case 'done': return 'success'
            default: return 'default'
        }
    }

    private parseFailedReason (failedReason: any) {
        try {
            if (!failedReason || !failedReason.code) return 'InternalError'
            switch (failedReason.code) {
                case 'InternalError':
                case 'FileNotReadable':
                case 'FileNotParsable':
                case 'DbNotReadable':
                case 'DbTableNotExist':
                case 'DbQueryError':
                case 'DbTableColumnNotExist':
                case 'DbLimitSettingsNotValid':
                case 'PaginatedSettingsPageKeyMissing':
                case 'ResponseCurrentPageNotFound':
                case 'ResponseTotalPageNotFound':
                case 'ApiCallFailed':
                case 'StrategyTypeIsNotSupported':
                case 'MaxExecutionTimeReached':
                case 'MaxContactsReached': return failedReason.code
                default: return 'InternalError'
            }
        } catch (e) {
            return 'InternalError'
        }
    }
}
