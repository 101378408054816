















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import ImportDetailsDrawer from '@/modules/lists/components/ImportDetailsDrawer/Index.vue'
import {
    getImports,
} from '@/api/consoleApi/imports'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
@Component({
    name: 'ImportHistory',
    components: {
        VsSectionHeader,
        ImportDetailsDrawer,
        VsTable,
    },
})
export default class extends Vue {
    private loading = false
    private showEmptyState = false
    private imports = []
    private tableColumns: any[] = []

    private pagination = {
        currentPage: 1,
        itemsPerPage: 20,
        totalItems: 0,
    }

    $refs!: {
        importDetailsDrawer: ImportDetailsDrawer
    }

    get listId () {
        return this.$route.params.listId
    }

    get importId () {
        return this.$route.params.importId
    }

    private beforeMount () {
        this.getTableHeaders()
    }

    private getStatusAspect (status: string) {
        switch (status) {
            case 'processing': return 'info'
            case 'failed': return 'alert'
            case 'done': return 'success'
            default: return 'default'
        }
    }

    @Watch('pagination', { immediate: true, deep: true })
    private async getImports () {
        this.loading = true
        this.showEmptyState = false
        try {
            const resp = await getImports({
                page: this.pagination.currentPage,
                limit: this.pagination.itemsPerPage,
                orderBy: 'id',
                sortedBy: 'desc',
                searchFields: 'entity_id,entity_type',
                searchJoin: 'and',
                search: `entity_id:${this.listId};entity_type:recipient`,
            })
            this.imports = resp.data.data
            this.pagination.totalItems = resp.data.meta.pagination.total
            this.showEmptyState = resp.data.meta.pagination.total === 0
        } catch (e) {
            this.imports = []
            this.pagination.totalItems = 0
        }
        this.loading = false
    }

    private async openDetail (importId: string) {
        this.$refs.importDetailsDrawer.openDrawer(importId)
    }

    private getTableHeaders () {
        const tableColumns: TableHeader[] = []

        tableColumns.push(
            {
                id: 'created_at',
                key: 'created_at',
                label: 'Data creazione',
                sortable: false,
                formatter: (value: any) => value === '0000-00-00 00:00:00' ? '' : this.$options?.filters?.formatUnixDate(value),
                visible: true,
            },
            {
                id: 'strategy',
                key: 'strategy',
                label: 'Tipo',
                formatter: (value: any) => {
                    return this.$t(`lists.importHistory.methods.${value}`).toString()
                },
                visible: true,
            },
            {
                id: 'total_new_contacts',
                key: 'total_new_contacts',
                sortable: false,
                label: 'Contatti inseriti',
                visible: true,
            },
            {
                id: 'total_updated_contacts',
                key: 'total_updated_contacts',
                sortable: false,
                label: 'Contatti aggiornati',
                visible: true,
            },
            {
                id: 'total_error_contacts',
                key: 'total_error_contacts',
                sortable: false,
                label: 'Errori rilevati',
                visible: true,
            },
            {
                id: 'status',
                key: 'status',
                sortable: false,
                label: 'Stato',
                formatter: (value: any) => {
                    return this.$t(`lists.importHistory.status.${value}`).toString()
                },
                visible: true,
            },
        )

        this.tableColumns = tableColumns
    }
}
